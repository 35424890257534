<template>
  <o-container ref="container">
    <v-card elevation="2" shaped class=" fill-height">
      <v-card-title v-if="action === ``" class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto ">
          {{ title }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
    
          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-sync"
            type="label-icon"
            label="Refresh"
            @on-click="reset()"
          />
          <o-button
            :busy="busy"
            color="warning"
            icon="mdi-filter"
            type="label-icon"
            label="Filter"
            @on-click="showFilter = true"
          />
          <div>
            <v-menu
              left
              bottom
            >
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  :color="timeout == 0 ? '' :'lighten-3  bg-primary'"
                  small
                  depressed
                  v-bind="attrs"
                  butt 
                  v-on="on"
                >
                  <template v-if="timeout == 0">
                    <span class="text-capitalize text-red">Manual</span>
                  </template>
                  <template v-if="timeout > 0">
                    <span class="text-capitalize">
                     
                      {{timeout / 60}} minutes
                    </span>
                  </template>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list style="cursor: pointer">
                <v-list-item>
                  <v-list-item-title @click="timerSetup(0)">
                    Manual
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title @click="timerSetup(120)">
                    2 minutes
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title @click="timerSetup(300)">
                    5 minutes
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title @click="timerSetup(600)">
                    10 minutes
                  </v-list-item-title>
                </v-list-item>                
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-card-title>
      <v-divider v-if="action === ``" />

      <!-- table -->
      <v-card-text v-if="action === ``" class="text-primary">
        <o-table
          ref="table"
          type="rest"
          :lazy="true"
          :busy="busy"
          :has-paging="false"
          :height="getHeight(442)"
          :fixed-header="true"
          method="POST"
          :rest="{
            url: `/rest/intranet/list`,
            payload: listParameters,
          }"
          :headers="headers"
          @set-busy="setBusy"
        >
          <template v-slot:slot="row">
            <div
              v-if="row.prop.key === `account_name`"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.account_id > 0">
                {{ row.prop.item.account_name }} ({{
                  row.prop.item.account_id
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === `advertiser_name`"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.advertiser_name != ``">
                {{ row.prop.item.advertiser_name }} ({{
                  row.prop.item.advertiser_id
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === `campaign_name`"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <!-- white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 300px;-->
              <template v-if="row.prop.item.campaign_id > 0">
                <router-link
                  :to="`/app/checker/campaigns?campaign_id=${row.prop.item.campaign_id}`"
                >
                  {{ row.prop.item.campaign_name }} ({{ row.prop.item.campaign_id }})
                </router-link>
              </template>
            </div>
            <div
              v-if="row.prop.key === `budget_type`"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.budget_type == 1">
                Impression
              </template>
              <template v-if="row.prop.item.budget_type == 2">
                Spend with Vendor Fees
              </template>
            </div>
            <div
              v-if="['campaign_budget', 'budget_used', 'available_budget', 'life_time'].includes(row.prop.key)"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.budget_type == 1">
                <span :class="[Number(row.prop.item[row.prop.key]) < 0 ? 'text-red': '']">{{getInteger(row.prop.item[row.prop.key])}}</span>
              </template>
              <template v-if="row.prop.item.budget_type == 2">
                <span :class="[Number(row.prop.item[row.prop.key]) < 0 ? 'text-red': '']">{{getDecimal(row.prop.item[row.prop.key])}}</span>
              </template>
            </div>

          </template>
        </o-table>
      </v-card-text>
 
      <!-- filter -->
      <o-drawer
        v-if="!busy"
        :auto-close="true"
        :actions="[
          {
            key: 'on-item-reset',
            label: 'RESET',
            icon: 'mdi-refresh',
            color: 'warning',
          },
          {
            key: 'on-item-search',
            label: 'SEARCH',
            icon: 'mdi-text-box-search-outline',
            color: 'success',
          },
        ]"
        button-icon="mdi-filter-plus-outline"
        :title="`FILTERS`"
        :use-button="false"
        width="50vw"
        :visible="showFilter"
        @on-item-search="
          showFilter = false;
          reset();
        "
        @on-item-reset="
          showFilter = false;
          reset(true);
        "
        @on-close="showFilter = false"
      >
        <v-row class="pa-0 ma-0">
          <v-col cols="4" offset="4" style="display:none">
            <o-check-box v-model="strictFilter" :busy="busy" label="Strict" />
          </v-col>
          <v-col cols="12"  class="pa-0">
            <o-combo-box
              v-model="parameters.account_id"
              :busy="busy"
              label="Account"
              :items="accounts"
              :multiple="true"
            />
          </v-col>
          <v-col cols="12" class="pa-0">            
            <o-input
              v-model="parameters.advertiser_id"
              :busy="busy"
              label="Advertiser ID"
              :hasClear="true"
            />
          </v-col>
          <v-col cols="12" class="pa-0">
            <o-input
              v-model="parameters.campaign_id"
              :busy="busy"
              label="Campaign ID"
              :hasClear="true"
            />
          </v-col> 
          <v-col cols="12" class="pa-0">
            <o-combo-box
              v-model="parameters.active"
              :busy="busy"
              label="Active"
              :items="booleans"
              :multiple="true"
              :hasId="false"
            />
          </v-col>
        </v-row>
      </o-drawer>
    </v-card>
  </o-container>
</template>

<script>
import RestApi from "@/services/RestApi";
import { mapGetters } from "vuex";

export default {
  name: `CheckerBudgetMonitor`,
  mixins: [RestApi],
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: `Budget Monitor`,
  },
  data() {
    return {
      title: `Budget Monitor`,
      busy: false,
      strictFilter: false,
      action: ``,
      valid: false, 
      timeout: 0,
      headers: [
        {
          text: `#`,
          align: `end`,
          value: `row_no`,
          type: `int`,
          width: 100,
        },
        {
          text: `Account Name`,
          align: `start`,
          sortable: false,
          value: `account_name`,
          type: `slot`,
        },
        {
          text: `Advertiser Name`,
          align: `start`,
          sortable: false,
          value: `advertiser_name`,
          type: `slot`,
        }, 
        {
          text: `Campaign Name`,
          align: `start`,
          sortable: false,
          value: `campaign_name`,
          type: `slot` 
        }, 
        {
          text: `Active`,
          align: `center`,
          value: `active`,
          type: `boolean`,
          width: 100,
        }, 
        {
          text: `Line Items`,
          align: `center`,
          value: `associated_line_items`,
          type: `int`,
          width: 100,
        }, 
        {
          text: `Start Date`,
          align: `start`,
          value: `start_date`,
          type: `date-time`,
          width: 100,
        }, 
        {
          text: `End Date`,
          align: `start`,
          value: `end_date`,
          type: `date-time`,
          width: 100,
        }, 
        {
          text: `Budget Type`,
          align: `start`,
          sortable: false,
          value: `budget_type`,
          type: `slot`,
          width: 150,
        }, 
        {
          text: `Campaign Budget`,
          align: `end`,
          value: `campaign_budget`,
          type: `slot`,
          width: 150,
        }, 
        {
          text: `Budget Used`,
          align: `end`,
          value: `budget_used`,
          type: `slot`,
          width: 150,
        },
        {
          text: `Available Budget`,
          align: `end`,
          value: `available_budget`,
          type: `slot`,
          width: 150,
        }, 
        {
          text: `Traffic Stats`,
          align: `end`,
          value: `life_time`,
          type: `slot`,
          width: 150,
        }, 
      ],
      parameters:{
        account_id: [],
        advertiser_id: ``,
        campaign_id: ``,
        active: [true, false]
      },
      accounts: [],
      advertiser_categories: [],
    };
  },
  computed: {
    ...mapGetters([`loggedInUser`]),
  },
  watch: { 
  },
  created() {
    this.listParameters.fields = [
      `ROW_NUMBER() OVER(ORDER BY a.available_budget ASC) row_no`,
      `a.*`
    ];
    this.listParameters.sources = {
      main: `vw_campaign_budget_checker a`,
      children: [],
    };
    this.listParameters.orders = [
     /* {
        sort: `a.account_id`,
        dir: `ASC`,
      }, */
      {
        sort: `a.available_budget`,
        dir: `ASC`,
      },
    ];
    for (const [k, v] of Object.entries(this.parameters)) { 
      if (this.$route.query[k]) {
        this.parameters[k] = this.$route.query[k];
      }
    }     
    const wheres = this.setParameters();
    if (wheres.length > 0)
      this.listParameters.wheres = [
        wheres.join(` AND `),
      ];
  },
  async mounted() {
     this.accounts = await this.getList(
      ["account_id as id, account_name as name"],
      "accounts",
      [],
      [],
      "name"
    ); 
  },
  methods: {
    timerSetup(t) {
      const _this = this;
      if (this.$route.name && this.$route.name !== 'checker_budget_monitor') {
        if (this.timeoutControl) {
          window.clearInterval(this.timeoutControl);
        }
      }

      this.timeout = t;      
      if (this.timeoutControl) {
        window.clearInterval(this.timeoutControl);
      }
      if (t > 0) {
        this.timeoutControl = window.setInterval(async() => { _this.reset() }, t * 1000);
      }
    },
    async reset(clear) { 
      await this.clearQueryString();

      this.listParameters.wheres = [];
      if (clear) {
        this.parameters = {
          account_id: [],
          advertiser_id: ``,
          campaign_id: ``,
          active: [true, false]    
        };
      }
      const wheres = this.setParameters();
      if (wheres.length > 0)
        this.listParameters.wheres = [
          wheres.join(` AND `),
        ];

      await this.changeQueryString(this.parameters);

      this.model = _.cloneDeep(this.baseModel);
      const _this = this;
      setTimeout(async () => {
        _this.$refs.table.refresh();
      }, 200);
    }, 
    setParameters() {
      const wheres = [];

      if (this.parameters.account_id.length > 0) { 
        wheres.push(
          `a.account_id in (${this.stringToStringArray(this.parameters.account_id).join(
            `, `
          )})`
        );
      }
      if (this.parameters.active.length > 0) {
        wheres.push(
          `a.active in (${this.stringToStringArray(this.parameters.active).join(
            `, `
          )})`
        );
      }
      if (this.parameters.campaign_id != ``) {
         wheres.push(`a.campaign_id in (${this.parameters.campaign_id})`);
      }
       if (this.parameters.advertiser_id != ``) {
         wheres.push(`a.advertiser_id in (${this.parameters.advertiser_id})`);
      }
      
      return wheres;
    }
  },
};
</script>
<style lang="scss" scoped>
.o-container-content {
  min-height: calc(100vh - 278px);
  max-height: calc(100vh - 278px);
  overflow-y: scroll;
}
</style>

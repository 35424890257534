<template>
  <BudgetMonitor dialog-name="Budget Monitor" />
</template>
<script>
import BudgetMonitor from '@/views/app/checker/BudgetMonitor'

export default {
  name: 'BudgetMonitorWrapper',
  components: {
    BudgetMonitor
  }
}
</script>
